import React from "react";
import { Col, Row } from "antd";
import { CustomCard } from "../utils/CustomCard";
import Spinner from "../utils/Spinner";
import { StyledButton } from "../utils/ResponsiveItems";

export const PowerNFT = ({
  handleMintNFT,
  data,
  loading,

  handlePWRRewardsNFT,
  handleUSDCRewardsNFT,
  registerNFTOwner,
}) => {

  console.log("data.nftPendingUSDCReward", data.nftPendingUSDCReward)
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col xs={12} sm={12} md={12} lg={8}>
          <CustomCard name="NFT's Remaining" value={data.nftRemaining || 0} />
        </Col>
        <Col xs={12} sm={12} md={12} lg={8}>
          <CustomCard name="Price Per NFT (USDC)" value={data.nftPrice || 0} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <div
            style={{
              background: "rgba(128, 128, 128, 0.1)",
              borderColor: "rgba(128, 128, 128, 0.5)",
              borderWidth: "3px",
              textAlign: "center",
              borderStyle: "solid",
              borderRadius: "4px",
              padding: "10px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledButton type="primary" onClick={handleMintNFT}>
              MINT NFT
            </StyledButton>
          </div>
        </Col>
      </Row>

      <Row gutter={[12, 12]} style={{ marginTop: "24px" }}>
        <Col xs={12} sm={12} md={12} lg={24}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Pending Reward"
                unit="(wPWR)"
                value={data.nftPendingPWRReward || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Total Earned"
                unit="(wPWR)"
                value={data.nftEarnedPWR || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div
                style={{
                  background: "rgba(128, 128, 128, 0.1)",
                  borderColor: "rgba(128, 128, 128, 0.5)",
                  borderWidth: "3px",
                  textAlign: "center",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data.isExistingInList ? (
                  <StyledButton type="primary" onClick={handlePWRRewardsNFT}>
                    CLAIM WPWR
                  </StyledButton>
                ) : (
                  <StyledButton type="primary" onClick={registerNFTOwner}>
                    REGISTER
                  </StyledButton>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={24}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Pending Reward"
                unit="(USDC)"
                value={data.nftPendingUSDCReward || 0}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Total Earned"
                unit="(USDC)"
                value={data.nftEarnedUSDC || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div
                style={{
                  background: "rgba(128, 128, 128, 0.1)",
                  borderColor: "rgba(128, 128, 128, 0.5)",
                  borderWidth: "3px",
                  textAlign: "center",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data.isExistingInList ? (
                  <StyledButton type="primary" onClick={handleUSDCRewardsNFT}>
                    CLAIM USDC
                  </StyledButton>
                ) : (
                  <StyledButton type="primary" onClick={registerNFTOwner}>
                    REGISTER
                  </StyledButton>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Spinner isVisible={loading} />
    </>
  );
};
