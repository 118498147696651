import React, { useEffect, useState } from "react";
import { notification, Row, Col, Layout, Button } from "antd";
import { useWeb3 } from "../utils/Web3Provider";
import { PowerNFT } from "./PowerNFT";
import { PowerDrip } from "./PowerDrip";
import PowerDripContract from "../../contracts/PowerDrip.json";
import PowerNFTContract from "../../contracts/PowerNFT.json";
import PowerTokenContract from "../../contracts/Power.json";
import USDCContract from "../../contracts/USDC.json";
import { StyledButton } from "../utils/ResponsiveItems";
import { ethers } from "ethers";
import { CustomCard } from "../utils/CustomCard";
import "../utils/CustomAntd.css";
import "./responsive.css";

const metaDataURI = "ipfs://QmSAkU8AZR5G3GCMrcx4BSamptZMZ8BY73HZ5KaEgLBoqK";
const { Header, Content } = Layout;

const Pages = () => {
  const {
    provider,
    account,
    handleToggle,
    powerDrip,
    powerNFT,
    powerToken,
    usdcToken,
  } = useWeb3();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 632);
  const [NFTTab, setNFTTab] = useState(false);

  const [data, setData] = useState({
    currentUser: null,

    // PWR Drip
    tokensStaked: "0",
    earnedUSDC: "0",
    earnedPWR: "0",
    pendingPWRReward: "0",
    pendingUSDCReward: "0",

    totalLockedAmount: "0",
    pwrBalance: "0",
    minStake: "0",
    stakingPaused: null,
    dripOwner: null,
    distributablePWRAmount: "0",
    distributableUSDCAmount: "0",
    stakerCount: 0,
    stakingDuration: "0",
    stakeTime: null,
    airDroppedAmount: "0",

    // NFT Drip
    owner: null,
    nftBalance: "0",
    totalNFTBalance: "0",
    nftPrice: "0",
    rewardableUSDCAmount: "0",
    rewardablePWRAmount: "0",
    nftPendingPWRReward: "0",
    nftPendingUSDCReward: "0",

    nftEarnedUSDC: "0",
    nftEarnedPWR: "0",
    nftOwnerCount: 0,
    nftRemaining: 0,
    usdcBalanceInWallet: "0",
  });

  const [lockAmount, setLockAmount] = useState("");
  const [usdcAmount, setUSDCAmount] = useState("");

  const [dropAddress, setDropAddress] = useState("");
  const [dropAmount, setDropAmount] = useState("");

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      //PWR Drip
      const signer = await provider.getSigner();

      const pwrBalance = await powerToken.connect(signer).balanceOf(account);

      const totalLockedAmount = await powerDrip.connect(signer).tokensStaked();
      const minStake = await powerDrip.connect(signer).minStake();
      const stakingPaused = await powerDrip.connect(signer).stakingPaused();
      const stakerVaults = await powerDrip.connect(signer).stakers(account);
      const stakeTime = await powerDrip.connect(signer).stakeTime();
      const dripOwner = await powerDrip.connect(signer).owner();
      const stakerCount = await powerDrip.connect(signer).stakerCount();
      const dropped = await powerDrip.connect(signer).stakes(account);
      const distributablePWRAmount = await powerDrip
        .connect(signer)
        .distributablePWRAmount();
      const distributableUSDCAmount = await powerDrip
        .connect(signer)
        .distributableUSDCAmount();

      //NFT Drip
      const nftBalance = await powerNFT.connect(signer).balanceOf(account);
      const owner = await powerNFT.connect(signer).owner();
      const totalNFTBalance = await powerNFT.connect(signer).totalSupply();
      const nftPrice = await powerNFT.connect(signer).nftPrice();
      const ownerVaults = await powerNFT.connect(signer).nftOwners(account);
      const nftOwnerCount = await powerNFT.connect(signer).ownerCount();
      const maxSupply = await powerNFT.connect(signer).maxSupply();

      const rewardablePWRAmount = await powerNFT
        .connect(signer)
        .rewardableTokenBalance(PowerTokenContract.address);

      // console.log("rewardablePWRAmount-----", PowerTokenContract.address)
      const rewardableUSDCAmount = await powerNFT
        .connect(signer)
        .rewardableTokenBalance(USDCContract.address);

      const isExistingInList = await powerNFT
        .connect(signer)
        .isExistingInList(account);
      const usdcBalanceInWallet = await usdcToken
        .connect(signer)
        .balanceOf(account);

      setData({
        currentUser: account,
        //PWR Drip
        tokensStaked: ethers.formatUnits(stakerVaults[0], "ether"),
        earnedUSDC: stakerVaults[6],
        earnedPWR: ethers.formatUnits(stakerVaults[4], "ether"),
        pendingPWRReward: ethers.formatUnits(stakerVaults[3], "ether"),
        pendingUSDCReward: stakerVaults[5],
        distributablePWRAmount: ethers.formatUnits(
          distributablePWRAmount,
          "ether"
        ),
        distributableUSDCAmount,

        totalLockedAmount: ethers.formatUnits(totalLockedAmount, "ether"),
        pwrBalance: ethers.formatUnits(pwrBalance, "ether"),
        minStake: ethers.formatUnits(minStake, "ether"),
        stakingPaused: stakingPaused,
        dripOwner,

        stakerCount,
        stakingDuration: stakerVaults[7],
        stakeTime,
        airDroppedAmount: dropped ? ethers.formatUnits(dropped, "ether") : 0,

        //NFT Drip
        owner,
        nftBalance,
        totalNFTBalance,
        nftPrice,
        nftPendingPWRReward: ethers.formatUnits(ownerVaults[0], "ether"),
        nftPendingUSDCReward: ownerVaults[2],

        nftEarnedUSDC: ownerVaults[3],
        nftEarnedPWR: ethers.formatUnits(ownerVaults[1], "ether"),
        nftOwnerCount,
        nftRemaining: maxSupply - totalNFTBalance,
        isExistingInList,
        usdcBalanceInWallet,
        rewardablePWRAmount,
        rewardableUSDCAmount,
      });
      console.log("dropped[0]", {
        currentUser: account,
        //PWR Drip
        tokensStaked: ethers.formatUnits(stakerVaults[0], "ether"),
        earnedUSDC: stakerVaults[6],
        earnedPWR: ethers.formatUnits(stakerVaults[4], "ether"),
        pendingPWRReward: ethers.formatUnits(stakerVaults[3], "ether"),
        pendingUSDCReward: stakerVaults[5],

        totalLockedAmount: ethers.formatUnits(totalLockedAmount, "ether"),
        pwrBalance: ethers.formatUnits(pwrBalance, "ether"),
        minStake: ethers.formatUnits(minStake, "ether"),
        stakingPaused: stakingPaused,
        dripOwner,

        stakerCount,
        stakingDuration: stakerVaults[7],
        stakeTime,
        airDroppedAmount: dropped ? ethers.formatUnits(dropped, "ether") : 0,
        distributablePWRAmount,
        distributableUSDCAmount,

        //NFT Drip
        owner,
        nftBalance,
        totalNFTBalance,
        nftPrice,
        nftPendingPWRReward: ethers.formatUnits(ownerVaults[0], "ether"),
        nftPendingUSDCReward: ownerVaults[2],

        nftEarnedUSDC: ownerVaults[3],
        nftEarnedPWR: ethers.formatUnits(ownerVaults[1], "ether"),
        nftOwnerCount,
        nftRemaining: maxSupply - totalNFTBalance,
        isExistingInList,
        usdcBalanceInWallet,
        rewardablePWRAmount,
        rewardableUSDCAmount,
      });
    } catch (error) {
      console.error("Error fetching data", error);
      notification.error({
        message: (
          <span style={{ fontFamily: "Outfit" }}>
            Failed to fetch data from base chain.
          </span>
        ),
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const fetchDataAsync = async () => {
      if (provider && account) {
        setLoading(true);
        await fetchData();
        setLoading(false);
      } else {
        setData({
          currentUser: null,

          // PWR Drip
          tokensStaked: "0",
          earnedUSDC: "0",
          earnedPWR: "0",
          pendingPWRReward: "0",
          pendingUSDCReward: "0",

          totalLockedAmount: "0",
          pwrBalance: "0",
          minStake: "0",
          stakingPaused: null,
          dripOwner: null,
          distributablePWRAmount: "0",
          distributableUSDCAmount: "0",
          stakerCount: 0,
          stakingDuration: "0",
          stakeTime: null,
          airDroppedAmount: "0",

          // NFT Drip
          owner: null,
          nftBalance: "0",
          totalNFTBalance: "0",
          nftPrice: "0",
          rewardableUSDCAmount: "0",
          rewardablePWRAmount: "0",
          nftPendingPWRReward: "0",
          nftPendingUSDCReward: "0",

          nftEarnedUSDC: "0",
          nftEarnedPWR: "0",
          nftOwnerCount: 0,
          nftRemaining: 0,
          usdcBalanceInWallet: "0",
          isExistingInList: false,
        });
      }
    };
    fetchDataAsync();
  }, [provider, data.earnedUSDC]);

  const handleDropTokens = async () => {
    try {
      const signer = await provider.getSigner();

      if (data.pwrBalance > 0) {
        setLoading(true);
        let amountInWei = ethers.parseUnits(dropAmount.toString(), "ether");
        const approveTx = await powerToken
          .connect(signer)
          .approve(PowerDripContract.address, amountInWei);
        const approveReceipt = await approveTx.wait();
        let receipt;
        if (approveReceipt.status === 1) {
          const tx = await powerDrip
            .connect(signer)
            .depositAndAssignTokens(dropAddress, amountInWei);
          receipt = await tx.wait();
        }

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Tokens were Dropped successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else notification.error({ message: "No enough tokens to drop." });
      setLoading(false);
    } catch (error) {
      console.error("Error Drop tokens", error);
      notification.error({
        message: <span style={{ fontFamily: "Outfit" }}>Failed to Drop.</span>,
      });
      setLoading(false);
    }
  };

  const handleWithDrawDropTokens = async () => {
    try {
      const signer = await provider.getSigner();
      setLoading(true);

      const dropped = await powerDrip.connect(signer).stakes(dropAddress);

      if (dropped >= dropAmount) {
        let amountInWei = ethers.parseUnits(dropAmount.toString(), "ether");
        const tx = await powerDrip
          .connect(signer)
          .withdrawAirDropTokens(dropAddress, amountInWei);

        // Wait for the transaction to be confirmed
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Dropped Tokens was unlocked successfully!
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        console.error("No enough tokens in that wallet.");
        notification.error({ message: "No enough tokens in that wallet" });
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error withdraw tokens", error);
      notification.error({ message: "Failed to withdraw tokens." });
      setLoading(false);
    }
  };

  // Claim Rewards In PWRDrip
  const handleClaimPWRRewards = async () => {
    try {
      const signer = await provider.getSigner();
      console.log("data.distributablePWRAmount", data.distributablePWRAmount);
      if (
        Number(data.distributablePWRAmount) > 0 ||
        Number(data.pendingPWRReward) > 0
      ) {
        setLoading(true);
        const tx = await powerDrip.connect(signer).claimPWR();
        // Wait for the transaction to be confirmed
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Rewards claimed successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        notification.error({
          message: (
            <span style={{ fontFamily: "Outfit" }}>No rewards to claim.</span>
          ),
        });
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: (
          <span style={{ fontFamily: "Outfit" }}>Failed to claim rewards.</span>
        ),
      });
      setLoading(false);
    }
  };
  const handleClaimUSDCRewards = async () => {
    try {
      const signer = await provider.getSigner();
      if (data.distributableUSDCAmount > 0 || data.pendingUSDCReward > 0) {
        setLoading(true);

        const tx = await powerDrip.connect(signer).claimUSDC();

        // Wait for the transaction to be confirmed
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Rewards claimed successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        notification.error({
          message: (
            <span style={{ fontFamily: "Outfit" }}>No rewards to claim.</span>
          ),
        });
        setLoading(false);
      }
    } catch (error) {
      notification.error({
        message: (
          <span style={{ fontFamily: "Outfit" }}>Failed to claim rewards.</span>
        ),
      });
      setLoading(false);
    }
  };

  // Staking
  const handleLockTokens = async () => {
    try {
      const signer = await provider.getSigner();

      if (lockAmount >= 1000) {
        if (data.pwrBalance >= lockAmount) {
          setLoading(true);
          let amountInWei = ethers.parseUnits(lockAmount.toString(), "ether");
          const approveTx = await powerToken
            .connect(signer)
            .approve(PowerDripContract.address, amountInWei);

          const approveReceipt = await approveTx.wait();
          let receipt;
          if (approveReceipt.status === 1) {
            const tx = await powerDrip.connect(signer).deposit(amountInWei);
            receipt = await tx.wait();
          }

          // Check if the transaction was successful (status === 1 means success)
          if (receipt.status === 1) {
            await fetchData();
            notification.success({
              message: (
                <span style={{ fontFamily: "Outfit" }}>
                  Tokens locked successfully.
                </span>
              ),
            });
          }
          setLoading(false);
        } else {
          notification.error({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Insufficient PWR balance in your wallet to lock tokens.
              </span>
            ),
          });
          setLoading(false);
        }
      } else
        notification.error({
          message: (
            <span style={{ fontFamily: "Outfit" }}>
              Minium quantity of token should be more than 1000!
            </span>
          ),
        });
    } catch (error) {
      console.error("Error locking tokens", error);
      notification.error({
        message: (
          <span style={{ fontFamily: "Outfit" }}>Failed to lock tokens.</span>
        ),
      });
      setLoading(false);
    }
  };

  const handleUnlockTokens = async () => {
    try {
      const signer = await provider.getSigner();

      if (lockAmount <= data.tokensStaked) {
        setLoading(true);
        let amountInWei = ethers.parseUnits(lockAmount.toString(), "ether");
        console.log("amountInWei", amountInWei);
        const tx = await powerDrip.connect(signer).withdraw(amountInWei);
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Tokens unlocked successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        notification.error({
          message: "You don't have enough locked tokens to withdraw",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error unlocking tokens", error);
      notification.error({ message: "Failed to unlock tokens." });
      setLoading(false);
    }
  };

  // Mint

  const registerNFTOwner = async () => {
    try {
      const signer = await provider.getSigner();
      if (data.nftBalance) {
        setLoading(true);
        const tx = await powerNFT.connect(signer).registerNFTWallet(account);
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Registered successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else notification.error({ message: "You don't have any PNFT now." });
    } catch (error) {
      console.error("Error registering wallet", error);
      notification.error({ message: "Failed to register wallet." });
      setLoading(false);
    }
  };
  const handleMintNFT = async () => {
    try {
      const signer = await provider.getSigner();
      setLoading(true);
      if (
        account &&
        data.owner &&
        account.toLowerCase() !== data.owner.toLowerCase()
      ) {
        if (data.usdcBalanceInWallet >= data.nftPrice) {
          await usdcToken
            .connect(signer)
            .approve(PowerNFTContract.address, data.nftPrice);
        } else {
          setLoading(false);
          throw new Error("No enough USDC to mint.");
        }
      }

      const tx = await powerNFT.connect(signer).mintNFT(metaDataURI);
      const receipt = await tx.wait();

      // Check if the transaction was successful (status === 1 means success)
      if (receipt.status === 1) {
        await fetchData();
        notification.success({
          message: (
            <span style={{ fontFamily: "Outfit" }}>
              NFT minted successfully.
            </span>
          ),
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error minting NFT", error.message);
      notification.error({ message: error.message });
      setLoading(false);
    }
  };

  // Claim Rewards In PWRNFT

  const handlePWRRewardsNFT = async () => {
    try {
      const signer = await provider.getSigner();
      console.log("data.rewardablePWRAmount", data.rewardablePWRAmount);
      if (
        Number(data.rewardablePWRAmount) > 0 ||
        Number(data.nftPendingPWRReward) > 0
      ) {
        setLoading(true);
        const tx = await powerNFT.connect(signer).claimPWRRewards();

        // Wait for the transaction to be confirmed
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Rewards claimed successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        notification.error({ message: "No rewards to claim." });
        setLoading(false);
      }
    } catch (error) {
      notification.error({ message: "Failed to claim NFT rewards." });
      setLoading(false);
    }
  };
  const handleUSDCRewardsNFT = async () => {
    try {
      const signer = await provider.getSigner();
      if (data.rewardableUSDCAmount > 0 || data.nftPendingUSDCReward > 0) {
        setLoading(true);
        const tx = await powerNFT.connect(signer).claimUSDCRewards();
        const receipt = await tx.wait();

        // Check if the transaction was successful (status === 1 means success)
        if (receipt.status === 1) {
          await fetchData();
          notification.success({
            message: (
              <span style={{ fontFamily: "Outfit" }}>
                Rewards claimed successfully.
              </span>
            ),
          });
        }
        setLoading(false);
      } else {
        notification.error({ message: "No rewards to claim." });
        setLoading(false);
      }
    } catch (error) {
      notification.error({ message: "Failed to claim NFT rewards." });
      setLoading(false);
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        background:
          'linear-gradient(90deg, rgba(27, 31, 34, 0.9) 0%, rgba(18, 20, 23, 0.9) 50%, rgba(27, 31, 34, 0.9) 100%), url("/decoration-back.svg")',
        fontFamily: "Outfit",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "overlay",
        backgroundSize: "100%",
      }}
    >
      <Header className="res-header">
        <Row
          justify="space-between"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="res-logo">
            <div>
              <img
                src={"/logo.png"}
                className="res-logo-image"
                height={"auto"}
                alt=""
              />
            </div>
            <span className="res-logo-text">WPWR ON BASE</span>
          </div>
          <div className="res-title-text">DASHBOARD</div>
          <div className="res-connect-btn">
            <div
              style={{
                marginTop: 16,
                ...(isSmallScreen && {
                  marginTop: 16,
                  marginRight: -20,
                }),
              }}
            >
              <w3m-button size={"sm"} style={{ width: "100%" }} />
            </div>
          </div>
        </Row>
      </Header>
      <Content className="res-content">
        <Row gutter={[12, 12]}>
          <Col xs={12} sm={12} md={12} lg={6}>
            <CustomCard
              name="Token Holdings"
              unit="(wPWR)"
              value={data.pwrBalance}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <CustomCard
              name="Tokens Locked"
              unit="(wPWR)"
              value={data.tokensStaked || 0}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <CustomCard
              name="POWERNFT’S OWNED"
              unit="&nbsp;"
              value={data.nftBalance}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <CustomCard
              name="RESTRICTED TOKENS"
              unit="(wPWR)"
              value={data.airDroppedAmount || 0}
            />
          </Col>
        </Row>
        <Row className="res-tab">
          <Button
            style={{
              background: NFTTab ? "none" : "#1e50e9",
              color: !NFTTab ? "white" : "grey",
              borderColor: "transparent",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Outfit",
              padding: 20,
              paddingLeft: 40,
              paddingRight: 40,
              fontSize: "18px",
              ...(isSmallScreen && {
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: "16px",
              }),
            }}
            onClick={() => setNFTTab(false)}
          >
            POWERDRIP
          </Button>
          <Button
            style={{
              background: NFTTab ? "#1e50e9" : "none",
              color: NFTTab ? "white" : "grey",
              borderColor: "transparent",
              borderRadius: 20,
              fontWeight: "bold",
              fontFamily: "Outfit",
              padding: 20,
              paddingLeft: 40,
              paddingRight: 40,
              fontSize: "18px",
              ...(isSmallScreen && {
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: "16px",
              }),
            }}
            onClick={() => setNFTTab(true)}
          >
            POWERNFT
          </Button>
        </Row>

        {!NFTTab ? (
          <PowerDrip
            data={data}
            lockAmount={lockAmount}
            setLockAmount={setLockAmount}
            handleUnlockTokens={handleUnlockTokens}
            handleLockTokens={handleLockTokens}
            handleDropTokens={handleDropTokens}
            handleWithDrawDropTokens={handleWithDrawDropTokens}
            setDropAddress={setDropAddress}
            dropAddress={dropAddress}
            setDropAmount={setDropAmount}
            dropAmount={dropAmount}
            loading={loading}
            setLoading={setLoading}
            handleClaimPWRRewards={handleClaimPWRRewards}
            handleClaimUSDCRewards={handleClaimUSDCRewards}
          />
        ) : (
          <PowerNFT
            data={data}
            handleMintNFT={handleMintNFT}
            usdcAmount={usdcAmount}
            setUSDCAmount={setUSDCAmount}
            loading={loading}
            handleUSDCRewardsNFT={handleUSDCRewardsNFT}
            handlePWRRewardsNFT={handlePWRRewardsNFT}
            registerNFTOwner={registerNFTOwner}
          />
        )}
      </Content>
    </Layout>
  );
};

export { Pages };
