import React from "react";
import { Col } from "antd";
import { StyledItemName } from "./ResponsiveItems";

export const CustomCard = ({ value, name, unit }) => {
  return (
    <Col
      style={{
        background: "rgba(128, 128, 128, 0.1)",
        borderColor: "rgba(128, 128, 128, 0.5)",
        borderWidth: "3px",
        textAlign: "center",
        borderStyle: "solid",
        borderRadius: "4px",
        padding: "10px",
      }}
      span={24}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="res-card">
          <StyledItemName className="name">{name.toUpperCase()}</StyledItemName>
          <StyledItemName className="unit">
            {unit?.toUpperCase()}
          </StyledItemName>
        </div>
        <span style={{ color: "#1e50e9", marginTop: "10px" }}>
          <StyledItemName>
            {unit === "(USDC)" || name === "Price Per NFT (USDC)"
              ? (Number(value) / 1000000).toLocaleString(undefined, {
                  minimumFractionDigits: Number(value) % 1 !== 0 ? 2 : 0,
                  maximumFractionDigits: 2,
                })
              : Number(value).toLocaleString(undefined, {
                  minimumFractionDigits: Number(value) % 1 !== 0 ? 2 : 0,
                  maximumFractionDigits: 2,
                })}
          </StyledItemName>
        </span>
      </div>
    </Col>
  );
};
