// src/App.js
import React from 'react';
import {Pages} from './components/pages/';
import { Web3Provider } from './components/utils/Web3Provider';

const App = () => {
  return (
    <Web3Provider>
      <div className="App">
        <Pages />
      </div>
    </Web3Provider>
  );
};

export default App;
