import styled from "styled-components";
import { Button } from "antd";

const StyledButton = styled(Button)`
  border-radius: 6px;
  background: #2663d6;
  color: white;
  font-family: "Outfit";
  transition: background-color 0.3s ease;

  /* Mobile devices */
  @media (min-width: 300px) and (max-width: 576px) {
    font-size: 10px;
    padding: 10px;
    font-weight: bold; /* Corrected fontWeight syntax */
  }

  /* Tablets */
  @media (min-width: 576px) and (max-width: 768px) {
    font-size: 13px;
    padding: 10px;
    font-weight: bold; /* Corrected fontWeight syntax */
  }

  /* Desktops */
  @media (min-width: 768px) {
    font-size: 13px;
    padding: 10px;
    font-weight: bold; /* Corrected fontWeight syntax */
  }

  &:hover {
    background-color: #3b7bff; /* Desired hover color */
  }
`;

const StyledItemName = styled.span`
  font-family: "Outfit";
  font-weight: bold;
  /* Mobile devices */
  @media (min-width: 300px) and (max-width: 576px) {
    font-size: 13px;
  }

  /* Tablets */
  @media (min-width: 576px) and (max-width: 768px) {
    font-size: 13px;
  }

  /* Desktops */
  @media (min-width: 768px) {
    font-size: 13px;
  }
`;

const StyledTitle = styled.span`
  font-family: "Outfit";
  color: #6d7687;

  /* Mobile devices */
  @media (min-width: 300px) and (max-width: 576px) {
    font-size: 23px;
    letter-spacing: 0.5px; /* Adds a bit of space between letters */
  }

  /* Tablets */
  @media (min-width: 576px) and (max-width: 768px) {
    font-size: 23px;
    letter-spacing: 1px;
  }

  /* Desktops */
  @media (min-width: 768px) {
    font-size: 23px;
    letter-spacing: 1.5px; /* Larger spacing for larger screens */
  }
`;

const StyledTabName = styled.span`
  font-family: "Outfit";
  color: "#252525",
    /* Mobile devices */ @media (min-width: 300px) and (max-width: 576px) {
    font-size: 16px;
  }

  /* Tablets */
  @media (min-width: 576px) and (max-width: 768px) {
    font-size: 20px;
    font-weight: bold;
  }

  /* Desktops */
  @media (min-width: 768px) {
    font-size: 30px;
    font-weight: bold;
  }
`;
export { StyledButton, StyledItemName, StyledTitle, StyledTabName };
