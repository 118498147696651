import React, { createContext, useContext, useEffect, useState } from "react";
import { notification } from "antd";
import PowerDripContract from "../../contracts/PowerDrip.json";
import PowerNFTContract from "../../contracts/PowerNFT.json";
import PowerTokenContract from "../../contracts/Power.json";
import USDCTokenContract from "../../contracts/USDC.json";
import {
  useAppKitProvider,
  useAppKitAccount,
  modal,
} from "@reown/appkit/react";
import { BrowserProvider, ethers } from "ethers";
import { createAppKit } from "@reown/appkit";
import { EthersAdapter } from "@reown/appkit-adapter-ethers";
// import { base, mainnet, arbitrum, solana, opBNB } from "@reown/appkit/networks";
import * as networks from "@reown/appkit/networks";
// 1. Get projectId from https://cloud.reown.com
const projectId = "f524cd6a834dbcc2d4216b591857ca0d";

// 2. Create your application's metadata object
const metadata = {
  name: "wPWR Base",
  description: "AppKit Example",
  url: "https://dapp.maxxchain.org", // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/179229932"],
};

// 3. Create a AppKit instance
const walletModal = createAppKit({
  adapters: [new EthersAdapter()],
  networks: Object.values(networks),
  defaultNetwork: networks.base,
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
  ],
  metadata,
  projectId,
  allowUnsupportedChain: true,
  features: {
    email: false,
    socials: [],
    // emailShowWallets: false,
    swaps: false,
    onramp: false,
  },
  themeVariables: {
    "--w3m-accent": "#1e50e9",
    "--w3m-color-mix": "#121417E5",
    "--w3m-color-mix-strength": 40,
    "--w3m-font-family": "Outfit",
  },
});

const Web3Context = createContext();
export const useWeb3 = () => useContext(Web3Context);

export const Web3Provider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);

  const [powerDrip, setPowerDrip] = useState(null);
  const [powerNFT, setPowerNFT] = useState(null);
  const [powerToken, setPowerToken] = useState(null);
  const [usdcToken, setUsdcToken] = useState(null);

  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider("eip155");

  useEffect(() => {
    const initContracts = async (signer) => {
      try {
        const powerDrip = new ethers.Contract(
          PowerDripContract.address,
          PowerDripContract.abi,
          signer
        );
        setPowerDrip(powerDrip);

        const powerNFT = new ethers.Contract(
          PowerNFTContract.address,
          PowerNFTContract.abi,
          signer
        );
        setPowerNFT(powerNFT);

        const powerToken = new ethers.Contract(
          PowerTokenContract.address,
          PowerTokenContract.abi,
          signer
        );
        setPowerToken(powerToken);

        const usdcToken = new ethers.Contract(
          USDCTokenContract.address,
          USDCTokenContract.abi,
          signer
        );
        setUsdcToken(usdcToken);
      } catch (error) {
        console.error(
          "Failed to initialize contracts or fetch accounts",
          error
        );
      }
    };

    const initEthers = async () => {
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      setProvider(ethersProvider);
      setAccount(signer.address);
      await initContracts(signer);
      // Listen for chain changes
      await walletProvider.on("chainChanged", (chainId) => {
        console.log("Chain changed to:", chainId);
        window.location.reload(); // Reload the browser when wallet changes
      });
      // Listen for wallet/account changes
      await walletProvider.on("accountsChanged", (accounts) => {
        console.log("Accounts changed to:", accounts);
        window.location.reload(); // Reload the browser when wallet changes
      });

      notification.success({
        message: "Wallet connected!",
      });
    };

    if (isConnected) initEthers();
    else {
      if (provider) {
        setProvider(null);
        setAccount(null);
        notification.success({
          message: "Wallet disconnected!",
        });
      }
    }

    return () => {
      if (walletProvider) {
        walletProvider.removeAllListeners("chainChanged");
        walletProvider.removeAllListeners("accountsChanged");
      }
    };
  }, [isConnected, walletProvider]);

  return (
    <Web3Context.Provider
      value={{
        provider,
        account,
        powerDrip,
        powerNFT,
        powerToken,
        usdcToken,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
