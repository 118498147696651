// client/src/index.js
import "buffer";
import React from "react";
import App from "./App";
import { Buffer } from "buffer";
import { createRoot } from 'react-dom/client';
import './index.css'

// Polyfill for Buffer
window.Buffer = window.Buffer || Buffer;

// Get the root element from the DOM
const container = document.getElementById("root");

// Create a root and render your app
const root = createRoot(container);
root.render(<App />);
