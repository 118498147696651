import React, { useEffect, useState } from "react";
import { Col, Row, InputNumber, Input, Popconfirm } from "antd";
import { CustomCard } from "../utils/CustomCard";
import { useWeb3 } from "../utils/Web3Provider";
import Spinner from "../utils/Spinner";
import { StyledButton } from "../utils/ResponsiveItems";
import "../utils/CustomAntd.css";

export const PowerDrip = ({
  setLockAmount,
  handleLockTokens,
  handleUnlockTokens,
  lockAmount,
  handleClaimUSDCRewards,
  handleClaimPWRRewards,
  data,

  dropAmount,
  setDropAmount,
  dropAddress,
  setDropAddress,
  handleDropTokens,
  handleWithDrawDropTokens,

  loading,
}) => {
  const [unStakeTime, setUnStakeTime] = useState(Math.floor(Date.now() / 1000));
  const { account } = useWeb3();

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && data.stakingDuration) {
        setUnStakeTime(
          Number(data.stakingDuration) +
            Number(data.stakeTime) -
            Math.floor(Date.now() / 1000)
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [data]);

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={24} lg={16}>
          <Row
            style={{
              background: "rgba(128, 128, 128, 0.1)",
              borderColor: "rgba(128, 128, 128, 0.5)",
              borderWidth: "3px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              borderStyle: "solid",
              borderRadius: "4px",
              padding: "10px",
              height: "100%",
              width: "100%",
              gap: 10,
            }}
          >
            <Col xs={12} lg={12}>
              <InputNumber
                formatter={(val) => {
                  if (!val) return "";
                  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }}
                parser={(val) => val.replace(/(,*)/g, "")} // Removes commas while typing
                value={lockAmount}
                min={1000}
                placeholder="ENTER AMOUNT"
                onChange={(value) => setLockAmount(value)}
                style={{
                  width: "100%",
                  background: "#fefefe",
                  fontSize: "16px",
                }}
              />
            </Col>
            <Col xs={5} lg={5}>
              <StyledButton
                style={{
                  width: "100%",
                }}
                onClick={handleLockTokens}
                type="primary"
              >
                LOCK
              </StyledButton>
            </Col>
            <Col xs={5} lg={5}>
              {(account &&
                data.dripOwner &&
                account.toLowerCase() === data.dripOwner.toLowerCase()) ||
              unStakeTime <= 0 ? (
                <StyledButton
                  style={{
                    width: "100%",
                  }}
                  type="primary"
                  onClick={handleUnlockTokens} // Ensure the function still triggers when unStakeTime > 0
                >
                  UNLOCK
                </StyledButton>
              ) : (
                <Popconfirm
                  title={
                    <span style={{ fontFamily: "Outfit" }}>
                      There is a 25% unlocking fee if tokens are removed prior
                      to unlock time.
                    </span>
                  }
                  onConfirm={handleUnlockTokens}
                  okText={<span style={{ fontFamily: "Outfit" }}>Unlock</span>}
                  cancelText={
                    <span style={{ fontFamily: "Outfit" }}>Cancel</span>
                  }
                >
                  <StyledButton
                    style={{
                      width: "100%",
                    }}
                    type="primary"
                  >
                    UNLOCK
                  </StyledButton>
                </Popconfirm>
              )}
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={8}>
          <CustomCard
            name="Lock Duration  ( seconds )"
            value={
              data.stakingDuration && unStakeTime > 0 && data.tokensStaked > 0
                ? unStakeTime.toString()
                : 0
            }
          />
        </Col>
      </Row>
      {account &&
        data.dripOwner &&
        account.toLowerCase() === data.dripOwner.toLowerCase() && (
          <Row gutter={[12, 12]} style={{ marginTop: 10 }}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row
                style={{
                  background: "rgba(128, 128, 128, 0.1)",
                  borderColor: "rgba(128, 128, 128, 0.5)",
                  borderWidth: "3px",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  padding: "10px",
                  height: "100%",
                  width: "100%",
                  gap: 10,
                }}
              >
                <Col xs={24} lg={7}>
                  <InputNumber
                    formatter={(val) => {
                      if (!val) return "";
                      return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }}
                    placeholder="Drop Amount"
                    value={dropAmount}
                    onChange={(value) => setDropAmount(value)}
                    style={{
                      width: "100%",
                      fontSize: "16px",
                      background: "#fefefe",
                      fontFamily: "Outfit",
                    }}
                  />
                </Col>

                <Col xs={24} lg={7}>
                  <Input
                    placeholder="Wallet Address"
                    value={dropAddress}
                    onChange={(e) => setDropAddress(e.target.value)} // Corrected event handling
                    style={{
                      width: "100%",
                      fontSize: "16px",
                      background: "#fefefe",
                      fontFamily: "Outfit",
                    }}
                  />
                </Col>
                <Col xs={10} lg={4}>
                  <StyledButton
                    onClick={handleWithDrawDropTokens}
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                  >
                    WITHDRAW
                  </StyledButton>
                </Col>
                <Col xs={10} lg={4}>
                  <StyledButton
                    onClick={handleDropTokens}
                    type="primary"
                    style={{
                      width: "100%",
                    }}
                  >
                    AIRDROP
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

      <Row gutter={[12, 12]} style={{ marginTop: "24px" }}>
        <Col xs={12} sm={12} md={12} lg={24}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Pending Reward"
                unit="(wPWR)"
                value={data.pendingPWRReward || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Total Earned"
                unit="(wPWR)"
                value={data.earnedPWR || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div
                style={{
                  background: "rgba(128, 128, 128, 0.1)",
                  borderColor: "rgba(128, 128, 128, 0.5)",
                  borderWidth: "3px",
                  textAlign: "center",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledButton type="primary" onClick={handleClaimPWRRewards}>
                  CLAIM WPWR
                </StyledButton>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={12} lg={24}>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Pending Reward"
                unit="(USDC)"
                value={data.pendingUSDCReward || 0}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={8}>
              <CustomCard
                name="Total Earned"
                unit="(USDC)"
                value={data.earnedUSDC || 0}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div
                style={{
                  background: "rgba(128, 128, 128, 0.1)",
                  borderColor: "rgba(128, 128, 128, 0.5)",
                  borderWidth: "3px",
                  textAlign: "center",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  padding: "10px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <StyledButton type="primary" onClick={handleClaimUSDCRewards}>
                  CLAIM USDC
                </StyledButton>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Spinner isVisible={loading} />
    </>
  );
};
