import React from "react";
import { Modal, Box, CircularProgress, Typography } from "@mui/material";

const TransparentSpinnerModal = ({ isVisible }) => {
  return (
    <Modal
      open={isVisible}
      closeAfterTransition
      BackdropProps={{
        style: {
          backgroundColor: "transparent", // Make the backdrop transparent
        },
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={{
          backgroundColor: "transparent", // Transparent background inside the modal
          outline: "none", // Remove focus outline
        }}
      >
        {/* Spinner */}
        <CircularProgress
          size={100}
          thickness={6}
          style={{ color: "#3f51b5", marginBottom: "20px" }} // Customize color
        />
      </Box>
    </Modal>
  );
};

export default TransparentSpinnerModal;
